:root {
  /* palette */

  --black-color: #1c1c1c;
  --dark-blue: #2d8aa9;
  --medium-blue: #b8dee8;
  --light-blue: #e8f7fb;
  --mint: #d4e5c5;
  --lime: #f0f4bf;
  --sand: #fde3c3;
  --off-white: #f8f8f8;
  --white: #ffffff;
  --firetruck: #fd8e81;
  --salmon: #fbb9b1;
  --light-pink: #fde3dc;
  --light-sand: #fff5ed;
  --beige: #f3eee9;


  /* themes */

  --page-color: white;
  --btn-bg-color: var(--page-color);
  --scrollbar-bg-color: var(--dark-gray-color);
  --thumb-bg-color: var(--page-color);

  --text-color: white;
  --secondary-text-color: var(--black-color);

  --image-placeholder-color: var(--light-gray-color);

  /* typography */

  --serif-fonts: Lora,'Lisu Bosa', serif;

  /* spacing system */
  --space-factor: 8px;

}

html {
    --section-background-color: linear-gradient(
      to bottom left,
      rgba(232, 229, 229, 0.582),
      rgba(12, 8, 24, 0.904)
    );
  
    --image-gradient: linear-gradient(
      to bottom left,
      rgba(17, 16, 16, 0.678),
      rgba(12, 10, 22, 0.863)
    );
  
    --imp-text-color: var(--black-color);
  }
  
  
  button:focus {
    box-shadow: none !important;
  }

  h1 {
    font-size: 3.5em;
    font-weight: 700;
    line-height: 1.8;
    color: var(--black-color);
  }

  h2 {
    font-size: 1.8em;
    line-height: 1.8;
    font-weight: 500;
    color: var(--black-color);
  }

  h3 {
    font-size: 1.5em;
    line-height: 1.8;
    font-weight: 500;
    color: var(--black-color);
  }
  
  /* --------- */
  /*  Preloader */
  /* --------- */
  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: var(--white);
    background-image: url(./assets/zip.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  
  #preloader-none {
    opacity: 0;
  }
  
  #no-scroll {
    overflow: hidden;
    height: 100vh;
  }
  
  /* --------- */
  /*Scrollbar   */
  /* --------- */
  
  ::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--black-color);
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--off-white);
    border-radius: 12px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: "gray";
    border-radius: 12px;
  }
  
  /* --------- */
  /* Navbar Section  */
  /* --------- */
  .sticky {
    /* background-color: #1b1a2ea9 !important; */
    transition: all 0.3s ease-out 0s !important;
    /* box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important; */
    /* backdrop-filter: blur(15px) !important; */
  }
  
  .navbar {
    position: fixed !important;
    transition: all 0.3s ease-out 0s !important;
    padding: 0.3rem 2rem !important;
    font-size: 1.2rem !important;
  }
  
  .navbar-toggler {
    position: relative !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }
  
  .navbar-toggler span {
    display: block !important;
    background-color: var(--black-color) !important;
    height: 4px !important;
    width: 27px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    transform: rotate(0deg) !important;
    left: 0 !important;
    opacity: 1 !important;
  }
  
  .navbar-toggler:focus,
  .navbar-toggler:active {
    outline: 0 !important;
  }
  
  .navbar-toggler span:nth-child(1),
  .navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out !important;
    transition: transform 0.35s ease-in-out !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(135deg) !important;
    opacity: 0.9 !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px !important;
    visibility: hidden !important;
    background-color: transparent !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(-135deg) !important;
    opacity: 0.9 !important;
  }
  
  @media (max-width: 767px) {
    .navbar {
      padding: 1rem 2rem !important;
      font-size: 1.4rem !important;
    }
    .navbar-nav .nav-item a::after {
      display: none !important;
    }
  }
  .navbar-brand {
    color: var(--black-color) !important;
  }
  
  .logo {
    height: 1.4em !important;
    width: 2.5em !important;
  }
  
  .navbar-nav .nav-link {
    color: var(--black-color) !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  
  .nav-link {
    padding: 0.8rem 1rem !important;
  }
  
  @media (max-width: 767px) {
    .nav-link {
      padding: 0.7rem 1rem !important;
    }
  }
  
  .navbar-nav .nav-item {
    position: relative;
    margin-left: 20px;
  }
  
  .navbar-nav .nav-item a {
    font-weight: 400;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 1;
  }
  
  .navbar-nav .nav-item a::after {
    content: "";
    position: relative;
    display: block;
    height: 5px;
    width: 0;
    border-radius: 16px;
    background: var(--medium-blue);
    bottom: 1px;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
  }
  
  .navbar-nav .nav-item a:hover::after {
    width: 100%;
  }
  
  /* --------- */
  /* Home section */
  /* --------- */
  .wave {
    animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
    animation-duration: 2.1s; /* Change to speed up or slow down */
    animation-iteration-count: infinite; /* Never stop waving :) */
    transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
    display: inline-block;
  }
  
  @keyframes wave-animation {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(14deg);
    } /* The following five values can be played with to make the waving more or less extreme */
    20% {
      transform: rotate(-8deg);
    }
    30% {
      transform: rotate(14deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(0deg);
    } /* Reset for the last half to pause */
    100% {
      transform: rotate(0deg);
    }
  }
  #tsparticles {
    position: fixed !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100%;
    height: 100%;
  }

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }  
  
  .home-header {
    padding-top: 40px;
  }

  .photo-caption {
    padding-top: 50px !important;
  }

  .landing-section {
    display: flex;
    flex-direction: row;
    z-index: -1;
    background-position: top center;
    background-repeat: no-repeat;
    padding-bottom: 30px !important;
    padding-top: 30px !important;
    min-height: 95vh;
    max-width: 97vw;
    align-items: flex-end;
    margin: 20px 20px 0px 15px;
    border: 1px solid var(--black-color);
  }

  .container-section {
    padding-top: 20px !important;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    height: 95vh;
    width: 97vw;
    border: 1px solid var(--black-color);
    margin: 20px 20px 0px 15px;
    text-align: right;
  }

  .home-section {
    display: flex;
    flex-direction: row;
    z-index: -1;
    background-position: top center;
    background-repeat: no-repeat;
    padding-top: 20px !important;
    max-height: 95vh;
    max-width: 97vw;
    align-items: flex-end;
    margin: 20px 20px 0px 15px;
    border: 1px solid var(--black-color);
  }
  
  .home-content {
    padding: 0 !important;
    color: var(--black-color);
    text-align: left;
  }

  .section-content {
    padding: 10 !important;
    color: var(--black-color);
    height: 95vh;
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .new-container-section {
    padding: 20px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 95vh;
    width: 100vw;
    border: 1px solid var(--black-color);
    margin: 20px 20px 0px 15px;
    text-align: right;
  }

  .home-column {
    width: 50%;
    height: 100vh;
    display: flex;
    align-items: right;
    justify-content: right;
    padding: 100px 0px 30px 0px;
  }

  .home-column-right{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    width: 70%;
    height: 100vh;
    padding: 20px 30px;
  }
  
  .image-stack__item--overlay {
    grid-column: 1 / -1;
    grid-row: 1;
    bottom: 0;
    right: 0;
    z-index: 2;
    padding: 30px 30px 50px 40px;
    max-height: 880px;
  }

  .image-stack__item--middle {
    grid-column: 2 / span 4;
    padding-top: 200px;
    max-height: 700px;
    grid-row: 1;
    z-index: 1;
  }

  .image-stack__item--bottom {
    grid-column: 7 / -1;
    padding-top: 530px;
    /* padding-left: 10px; */
    grid-row: 1;
    max-height: 760px;
    z-index: 0
  }

  .stacked-photos {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 50px;
    text-align: right;
  }

  .stacked-photo {
    max-height: 250px;
    align-self: center;
  }

  /* Style the container */
  .video-container {
    grid-column: 6 / span 5;
    grid-row: 1; 
    z-index: 3;
    padding-top: 100px;
    padding-left: 30px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 290px;
    overflow: hidden;
    position: relative;
  }

/* Style the image */
.hover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Initially hide the video */
.hover-video {
  width: 100%;
  height: 100%;
  position: absolute; /* Add this line */
  left: 30px;
  object-fit: cover;
  opacity: 0; /* Hide the video by default */
  transition: opacity 0.3s ease; /* Add a smooth transition effect */
}

/* Show the video on hover */
.video-container:hover .hover-video {
  opacity: 1;
}

.yymmdd-caption {
  padding-right: 20px;
}

.main-caption {
  padding-left: 20px;
  text-align: left;
}

.img-icon {
  max-width: 15vw;
  max-height: 15vh;
}

/* Media query for smaller screens (mobile devices) */
@media (max-width: 786px) {
  .landing-section {
    display: flex;
    flex-direction: row;
    z-index: -1;
    background-position: top center;
    background-repeat: no-repeat;
    min-height: 30vh;
    max-width: 80vw;
    align-items: flex-end;
  }

  .home-section {
    display: flex;
    flex-direction: row;
    z-index: -1;
    background-position: top center;
    background-repeat: no-repeat;
    max-height: 100vh;
    max-width: 80vw;
    align-items: flex-start;
  }

  .page-section {
    display: flex;
    flex-direction: row;
    z-index: -1;
    max-height: 100vh;
    max-width: 80vw;
    align-items: flex-start;
  }
  
  .home-content {
    color: var(--black-color);
    text-align: right;
  }

  .home-column {
    width: 50vw;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: right;
    padding: 0 2vw 0 2vw;
  }

  .home-column-right{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    width: 70%;
    max-height: 100vh;
    max-width: 70vh;
    padding: 0;
  }

  .image-stack__item--overlay {
    grid-column: 1 / -1;
    grid-row: 1;
    bottom: 0;
    right: 0;
    z-index: 2;
    max-height: 100vh;
    max-width: 90vw;
    overflow: hidden;
  }

  .image-stack__item--middle {
    grid-column: 2 / span 4;
    padding-top: 30vh;
    max-height: 100vh;
    grid-row: 1;
    z-index: 1;
    max-width: 30vw;
  }

  .image-stack__item--bottom {
    grid-column: 7 / -1;
    padding-top: 50vh;
    grid-row: 1;
    max-height: 100vh;
    z-index: 0;
    max-width: 35vw;
  }

  .stacked-photos {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    /* padding-top: 10vh; */
    gap: 10vh;
    text-align: right;
    align-self: right;
    padding: 10vh 5vw 0 10vw;
  }

  .stacked-photo {
    max-height: 20vh;
    align-self: flex-start;
  }

  .video-container {
    grid-column: 6 / span 5;
    grid-row: 1; 
    z-index: 3;
    padding-left: 10vw;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    position: relative;
    min-width: 40vw;
    max-height: 40vh;
  }

  /* Style the image */
  .hover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Initially hide the video */
  .hover-video {
    width: 100%;
    height: 100%;
    left: 10vw;
    position: absolute; /* Add this line */
    object-fit: cover;
    opacity: 0; /* Hide the video by default */
    transition: opacity 0.3s ease; /* Add a smooth transition effect */
  }

  /* Show the video on hover */
  .video-container:focus .hover-video {
    opacity: 1;
  }

  .yymmdd-caption {
    font-size: 2em;
  }
}

  .heading {
    font-size: 2.4em !important;
    padding-left: 50px !important;
  }
  
  .heading-name {
    font-size: 2.5em !important;
    padding-left: 45px !important;
  }
  
  .main-name {
    color: var(--black-color);
  }
  
  .Typewriter__wrapper {
    font-size: 3.5em !important;
    color: var(--black-color) !important;
    font-weight: 800 !important;
  }
  .Typewriter__cursor {
    font-size: 3.5em !important;
    color: var(--black-color) !important;
  }
  
  @media (max-width: 767px) {
    .Typewriter__wrapper {
      font-size: 1.4em !important;
      font-weight: 700 !important;
      position: absolute !important;
    }
    .Typewriter__cursor {
      display: none !important;
    }
  }
  
  .myAvtar {
    justify-content: center !important;
    padding-top: 9em !important;
  }
  
  @media (max-width: 767px) {
    .myAvtar {
      padding-top: 2em !important;
      padding-bottom: 2em !important;
    }
  }

  .left-column {
    width: 50vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2vw 0 0;
  }

  .right-column {
    width: 70vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2vw 0 2vw;
  }
  
  .img-horizontal {
    max-height: 30vh;
    max-width: 28vw;
  }

  .img-vertical {
    max-width: 25vw;
  }

  .stacked-images{
    display: flex;
    flex-direction: column;
    gap: 30px
  }

  .stop-motion-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  
  .stop-motion-gallery img {
    max-width: 50vh;
    max-height: 50vh;
    object-fit: contain;
  }

  /* --------- */
  /* Footer */
  /* --------- */
  .footer {
    bottom: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 8px !important ;
  }
  .footer-copywright {
    text-align: center !important;
  }
  
  .footer-body {
    z-index: 1;
    padding-top: 10px !important;
    padding-bottom: 8px;
    text-align: center !important;
  }

  .social-icon{
    display: inline;
    padding: 10px 10px;
    text-decoration: none;
    color: black;
    transition: all 0.4s ease 0s !important;
  }

  .social-icon:hover {
    transform: scale(1.05) !important;
    overflow: hidden !important;
    /* border: 2.2px solid rgba(197, 115, 230, 0.883) !important; */
    box-shadow: 4px 5px 4px 3px var(--medium-blue) !important;
  }
  
  @media (max-width: 767px) {
    .footer-copywright {
      text-align: center !important;
    }
  
    .footer-body {
      text-align: center !important;
    }
  }
  
  .footer h3 {
    font-size: 1em;
    color: var(--black-color) !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }
  .footer-icons {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
    padding: 0 !important;
  }
  
  .blockquote-footer {
    color: var(--black-color) !important;
  }
